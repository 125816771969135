<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header
          title="LOG ACCESSI SIMULTANEI"
          @backClick="$router.back()"
        />

        <list-table
          v-model="query"
          :pagination="pagination"
          :columns="tableColumns"
          :items="concurrentLogins"
          :search-fields="searchFields"
          :meta="meta"
          :creable="false"
          :editable="false"
          :deletable="false"
          @onShow="$router.push(`log-utenti/${$event.user_id}/dettagli`)"
        />
      </card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { ListTable } from '@/theme/components/index'

import secureStorage from '@/utils/secureStorage'

export default {
  components: {
    ListTable
  },

  data () {
    return {
      query: {
        rowsPerPage: 10,
        page: 1,
        sort: null,
        filter: {
          custom_search: ''
        }
      },
      pagination: {
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchFields: [
      ],
      tableColumns: [
        {
          prop: 'user_id',
          label: 'Numero',
          sortable: true,
        },
        {
          prop: 'user.full_name',
          label: 'Agente',
          sortable: true,
        },
        {
          prop: 'date',
          label: 'Data ultimo evento',
          sortable: true,
        },
        {
          prop: 'events_counter',
          label: 'Totale eventi',
          sortable: true,
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      concurrentLogins: 'concurrentLogins/items',
      meta: 'concurrentLogins/meta'
    })
  },

  watch: {
    query: {
      handler () {
        this.handleFetch()
      },
      deep: true
    },
    meta: {
      handler (val) {
        this.pagination.total = val.total
      },
      deep: true
    }
  },

  created () {
    const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
    if (storedQuery && storedQuery.entity === 'concurrentLogins') {
      this.query = storedQuery.query
    } else {
      this.handleFetch()
    }
  },

  beforeDestroy () {
    this.clear_ConcurrentLogins()
  },

  methods: {
    ...mapActions({
      fetch_ConcurrentLogins: 'concurrentLogins/fetch',
      clear_ConcurrentLogins: 'concurrentLogins/resetItems'
    }),
    handleFetch () {
      this.fetch_ConcurrentLogins(this.query)
      secureStorage.setItem('tableQuery', JSON.stringify({ entity: 'concurrentLogins', query: this.query }))
    },
  }
}
</script>
<style></style>
