<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header title="LOG GENERAZIONE PDF" :query="query" @backClick="$router.back()" />
        <list-table
          v-model="query"
          :pagination="pagination"
          :columns="computedColumns"
          :items="computations"
          :meta="meta"
          :search-fields="searchFields"
          hide-default-search
          :deletable="false"
          :editable="false"
          :creable="false"
          :showable="false"
          :hasActions="false"
        />
      </card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ListTable } from '@/theme/components/index'
import ComputationMixin from '@/mixins/ComputationMixin'
import secureStorage from '@/utils/secureStorage'
import axios from 'axios'
import swal from "sweetalert2";

export default {
  components: {
    ListTable
  },

  data() {
    return {
      isDownloading: false,
      query: {
        rowsPerPage: 10,
        page: 1,
        sort: null,
        filter: {
          custom_search: ''
        }
      },
      pagination: {
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchFields: [
        {
          type: 'input',
          propName: 'custom_search',
          name: 'Nome'
        }
      ],
      tableColumns: [
        {
          prop: 'user.full_name',
          label: 'Agente',
          sortable: true,
          admin: true
        },
        {
          prop: 'created_at',
          label: 'Data',
          sortable: true
        },
        {
          prop: 'full_address',
          label: 'Indirizzo'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      computations: 'computations/items',
      meta: 'computations/meta'
    }),
    computedColumns() {
      if(this.user.user_level_id == 1) {
        return this.tableColumns
      }
      return this.tableColumns.filter(col => !col.admin)
    }
  },

  watch: {
    query: {
      handler() {
        this.handleFetch()
      },
      deep: true
    },
    meta: {
      handler(val) {
        this.pagination.total = val.total
      },
      deep: true
    }
  },

  created() {
    const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
    if (storedQuery && storedQuery.entity === 'log_computations') {
      this.query = storedQuery.query
    } else {
      this.handleFetch()
    }
  },

  beforeDestroy() {
    this.clearComputations()
  },

  methods: {
    ...mapActions({
      fetchComputations: 'computations/fetch',
      clearComputations: 'computations/resetItems'
    }),
    handleFetch() {
      this.fetchComputations(this.query)
      secureStorage.setItem('tableQuery', JSON.stringify({ entity: 'log_computations', query: this.query }))
    }
  }
}
</script>
