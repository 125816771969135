<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header
            :title="`LOG ACCESSI SIMULTANEI - AGENTE: ${user.full_name}`"
            @backClick="$router.back()"
        />

        <list-table
            v-model="query"
            :hideDefaultSearch="true"
            :pagination="pagination"
            :columns="tableColumns"
            :items="concurrentLogins"
            :meta="meta"
            :creable="false"
            :editable="false"
            :deletable="false"
            :showable="false"
        />
      </card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { ListTable } from '@/theme/components/index'

import secureStorage from '@/utils/secureStorage'

export default {
  components: {
    ListTable
  },

  data () {
    return {
      userId: null,
      query: {
        rowsPerPage: 10,
        page: 1,
        sort: null,
      },
      pagination: {
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      tableColumns: [
        {
          prop: 'date',
          label: 'Data',
          sortable: true,
        },
        {
          prop: 'old_ip',
          label: 'Vecchio Indirizzo IP',
          sortable: true,
        },
        {
          prop: 'new_ip',
          label: 'Nuovo Indirizzo IP',
          sortable: true,
        },
        {
          prop: 'old_user_agent',
          label: 'Vecchio Browser',
          sortable: true,
          minWidth: 200,
        },
        {
          prop: 'new_user_agent',
          label: 'Nuovo Browser',
          sortable: true,
          minWidth: 200,
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'users/single',
      concurrentLogins: 'concurrentLogins/items',
      meta: 'concurrentLogins/meta'
    })
  },

  watch: {
    query: {
      handler () {
        this.handleFetch()
      },
      deep: true
    },
    meta: {
      handler (val) {
        this.pagination.total = val.total
      },
      deep: true
    }
  },

  created () {
    this.userId = this.$route.params.user_id
    this.getUser(this.userId)
    const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
    if (storedQuery && storedQuery.entity === 'concurrentLoginsDetails') {
      this.query = storedQuery.query
    } else {
      console.log('handleFetch')
      this.handleFetch()
    }
  },

  beforeDestroy () {
    this.clear_ConcurrentLogins()
  },

  methods: {
    ...mapActions({
      fetch_ConcurrentLogins: 'concurrentLogins/fetchDetails',
      clear_ConcurrentLogins: 'concurrentLogins/resetItems',
      getUser: 'users/get',
    }),
    handleFetch () {
      this.fetch_ConcurrentLogins(this.userId)
      secureStorage.setItem('tableQuery', JSON.stringify({ entity: 'concurrentLoginsDetails', query: this.query }))
    },
  }
}
</script>
<style></style>
