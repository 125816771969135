<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header
          title="NEWS"
          @backClick="$router.back()"
        />
        <div v-if="news && news.length">
          <h2 class="element">News e aggiornamenti dal mondo FIAIP</h2>
          <div class="grid">
            <div class="elem" v-for="item in news" :key="item.id">
              <div>
                <img :src="item.img_url" class="element" alt="Image" />
              </div>
              <div class="text-box">
                <div >
                  <p class="element" v-html="decodeHTMLEntities(item.excerpt)"></p>
                <div class="bottom-elements">
                  <a class="element" :href="item.post_url" target="_blank" ><span class="icon" role="img"><img src="/icons/Icon_ionic-ios-arrow-forward.svg"></span>  Scopri di più</a>
                  <p class="date">{{ item.date }}</p>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { ListTable } from '@/theme/components/index'

import secureStorage from '@/utils/secureStorage'
import news from "../../../store/modules/news";


export default {
  components: {
     ListTable
  },

  data () {
    return {
      query: {
        rowsPerPage: 10,
        page: 1,
        sort: null,
        filter: {
          custom_search: ''
        }
      },
      pagination: {
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchFields: [
      ],
    }
  },

  computed: {
    news() {
      return news
    },
    ...mapGetters({
      news: 'news/items',
      //user: 'auth/user',
      meta: 'news/meta'
    })
  },

  watch: {
    query: {
      handler () {
        this.handleFetch()
      },
      deep: true
    },
  },

  created () {
    const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
    if (storedQuery && storedQuery.entity === 'news') {
      this.query = storedQuery.query
    } else {
      this.handleFetch()
    }
  },

  beforeDestroy () {
    this.clear_Errors()
  },

  methods: {
    ...mapActions({
      fetch_News: 'news/fetch'
    }),
    handleFetch () {
      this.fetch_News(this.query)
      secureStorage.setItem('tableQuery', JSON.stringify({ entity: 'news', query: this.query }))
    },
    decodeHTMLEntities(text) {
      var textArea = document.createElement('textarea');
      textArea.innerHTML = text;
      return textArea.value;
    }
  }
}
</script>
<style lang="css" scoped>

div.elem {
  width: 390px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
}
div.grid {
  display: grid;
  gap: 15px;
  margin-left: 70px;
  margin-right: 375px;
}
@media (min-width: 320px) and (max-width: 450px) {
  .grid {
    grid-template-columns: 1fr;
    margin-left: 0 !important;
    margin-right: 0;
  }
  div.elem {
    width: 300px;
  }
  img.element {
    height: 230px !important;
  }
  p.element {
    width: 290px !important;
  }
  h2.element {
    width: 300px !important;
  }
}


@media (min-width: 800px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1300px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
h2.element {
  /* Layout Properties */
  top: 93px;
  left: 299px;
  width: 400px;
  height: 34px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 26px/30px Futura PT;
  letter-spacing: 0px;
  color: #1F3968;
  opacity: 1;
}
img.element {
  border-radius: 0;
}
.text-box {
  padding: 15px 15px 20px 15px;
  background-color: #FBF8FA;
}
.icon {
  position: relative;
  top: -2px;
}

p.element{
  /* Layout Properties */
  top: 433px;
  left: 360px;
  width: 366px;
  height: 72px;
  margin-bottom: 5px;
  /* UI Properties */
  overflow: hidden;
  text-align: left;
  font: normal normal normal 18px/23px Futura PT;
  letter-spacing: 0px;
  color: #1F3968;
  opacity: 1;
  border-radius: 0;
}
p.date {
  /* Layout Properties */
  top: 510px;
  left: 614px;
  width: 120px;
  height: 23px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 18px/22px Futura PT;
  letter-spacing: 0px;
  color: #848484;
  opacity: 1;
  border-radius: 0;
}
a.element {
  /* Layout Properties */
  top: 513px;
  left: 375px;
  width: 170px;
  height: 23px;
  /* UI Properties */
  font: normal normal normal 18px/20px Futura PT;
  letter-spacing: 0px;
  color: #BE9249;
  text-transform: uppercase;
  opacity: 1;
}
img.element {
  /* Layout Properties */
  top: 167px;
  left: 345px;
  width: 390px;
  height: 250px;
  /* UI Properties */
  opacity: 1;
}
.bottom-elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

</style>
