<template>
  <div class="row primary-color" v-if="locationRates">
    <div class="col-6 mt-3">
      <h3>Riepilogo Caratteristiche Alloggio</h3>
      <div>Comune di {{ locationRates.municipality }}</div>
      <div>
        Indirizzo {{ locationRates.address_street }}
        <span v-if="locationRates.address_street_number"> n. {{ locationRates.address_street_number }}</span>
        <span v-if="locationRates.address_apartment_number"> int. {{ locationRates.address_apartment_number }}</span>
        <span v-if="locationRates.address_stair"> scala {{ locationRates.address_stair }}</span>
      </div>
      <div>Superficie reale: <b>{{ locationRates.real_surface }} mq</b></div>
      <div>Superficie ricalcolata: <b>{{ locationRates.updated_surface }} mq</b></div>
      <div>Circoscrizione <b>{{ locationRates.district_number }} - {{ locationRates.district_name }}</b></div>
      <div>Area di appartenenza <b>{{ locationRates.area_code }} - {{ locationRates.area_name }}</b></div>
      <div>Numero totale di elementi caratteristici: <b>{{ locationRates.elements_count }}</b></div>
      <div>Numero di elementi base: <b>{{ locationRates.elements_base_count }}</b></div>
      <div>Numero di elementi di ristrutturazione: <b>{{ locationRates.elements_renovation_count }}</b></div>
      <div>Sottofascia <b>{{ locationRates.subzone }} - {{ locationRates.subzone_name }}</b></div>
      <div>Valori dalla tabella degli Accordi Territoriali: <b>min {{ locationRates.original_min_value }} - max {{ locationRates.original_max_value }}</b></div>
      <div>Valori di sottofascia: <b>min {{ locationRates.updated_min_value }} - max {{ locationRates.updated_max_value }}</b></div>
      <br>
      <div>Aumenti percentuali:</div>
      <div v-for="item in locationRates.increments_description">
        {{ `${item.description}` }}: <b>{{ `${item.increment_perc}%` }}</b>
      </div>
      <div>Totale aumenti percentuali: <b>{{ locationRates.increment_percent}}%</b></div>
    </div>
    <div class="col-6 mt-3">
      <h3>Calcolo del Canone di Locazione</h3>
      <div>Canone Annuo Minimo: <b>€ {{ locationRates.min_yearly_rate }}</b></div>
        <div>Canone Annuo Massimo: <b>€ {{ locationRates.max_yearly_rate }}</b></div>
        <div>Canone Annuo Minimo Inderogabile: <b>€ {{ locationRates.mandatory_min_yearly_rate }}</b></div>
        <div>Canone Mensile Minimo: <b>€ {{ locationRates.min_monthly_rate }}</b></div>
        <div>Canone Mensile Massimo: <b>€ {{ locationRates.max_monthly_rate }}</b></div>
        <div>Canone Mensile Minimo Inderogabile: <b>€ {{ locationRates.mandatory_min_monthly_rate }}</b></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    locationRates: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang="scss">
.row.primary-color{
  line-height: 30px;
  color: #1F3968;
  h3{
    color: #1F3968;
  }
}
</style>
