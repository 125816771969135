<template>
  <div class="row">
    <div class="col-md-12">
      <user-profile-form />
    </div>
  </div>
</template>
<script>
import UserProfileForm from './Form.vue'

export default {
  components: {
    UserProfileForm
  }
}
</script>
<style></style>
